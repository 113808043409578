/*
 * @Author: your name
 * @Date: 2021-02-19 14:28:56
 * @LastEditTime: 2021-02-26 11:50:29
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /ksh-group-live/src/store/module/common/action.js
 */

import * as LiveService from '@/services/liveService'
export default {
  updateAllMuteAudioStatus ({ commit }, params) {
    return LiveService.setWebinarForbidden({ ...params }).then(res => {
      commit('updateRoomConfig', {
        isAllMuteAudio: params.forbidden
      })
      return Promise.resolve(res)
    }).catch(err => {
      return Promise.reject(err)
    })
  },
  setCoPresent({ commit }, params) {
    return LiveService.setCoPresent({ ...params }).then(res => {
      // 如果是自己转移则设置为嘉宾
      if (params.transfer && !params.state) {
        commit('updateUserConfig', {
          identity: 1
        })
      }
      if (res.code === 0) {
        return Promise.resolve(res)
      } else {
        return Promise.reject(new Error(res.message))
      }
    }).catch(err => {
      return Promise.reject(err)
    })
  },
  updateShareControl({ commit }, params) {
    return LiveService.setShareControl({ ...params }).then(res => {
      commit('updateRoomConfig', {
        shareControl: params.shareControl
      })
      return Promise.resolve(res)
    }).catch(err => {
      return Promise.reject(err)
    })
  },
  updateViewLayout({ commit }, params) {
    return LiveService.setWebinarLayout({ ...params }).then(res => {
      commit('updateUserConfig', {
        viewLayout: params.layout
      })
      return Promise.resolve(res)
    }).catch(err => {
      return Promise.reject(err)
    })
  }
}
