/*
 * @Author: your name
 * @Date: 2020-12-10 17:48:15
 * @LastEditTime: 2021-03-06 18:09:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/store/module/user/index.js
 */
import actions from './action'
import mutations from './mutation'
import { getUserInfo } from '@/utils/auth'

const state = {
  wxCodeImage: {
    qrCodeUrl: '',
    loopKey: ''
  }, // 获取登录扫码的二维码链接和循环标识
  userInfo: getUserInfo() || {}, // 用户信息
  // permission: getPermission() || {},//用户权限
  factoryAccountList: [], // 切换厂家账号
  paymentCodeImage: [], // 支付扫码的二维码
  captchaAppId: '2065962030', // 腾讯云验证码appid
  isHandleFactory: false, // 是否切换厂家
  accountCenterInfo: {
    accountInfoVO: {}
  }, // 安全中心个人信息
  accountLoginRecord: [], // 安全中心登录记录
  accountSensitiveRecord: [], // 安全中心敏感记录
  permissionRoles: [], // 用户权限列表
  loginTypeData: {
    0: '账号登录',
    1: '验证码登录',
    2: '微信扫码登录',
    3: '选择子账号登录'
  }// 登录方式数据
}
const getters = {
  isHandleFactory: state => state.isHandleFactory,
  wxCodeImage: state => state.wxCodeImage,
  userInfo: state => state.userInfo,
  permission: state => state.permission,
  factoryAccountList: state => state.factoryAccountList,
  paymentCodeImage: state => state.paymentCodeImage,
  accountCenterInfo: state => state.accountCenterInfo,
  accountLoginRecord: state => state.accountLoginRecord,
  accountSensitiveRecord: state => state.accountSensitiveRecord,
  captchaAppId: state => state.captchaAppId,
  permissionRoles: state => state.permissionRoles,
  loginTypeData: state => state.loginTypeData,
  speakerExe: state => state.speakerExe
}
export default {
  state,
  getters,
  actions,
  mutations
}
