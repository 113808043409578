/*
 * @Author: your name
 * @Date: 2020-12-15 18:09:01
 * @LastEditTime: 2021-03-04 21:09:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/services/userService.js
 */
import request from '@/utils/request'
import API from '@/apis/api-types'

export function getUserInfo () {
  return request({
    url: API.getUserInfo,
    method: 'get'
  })
}
export function getWxCodeImage (params) {
  return request({
    url: API.getWxCodeImage,
    method: 'get',
    params: { ...params, _t: new Date().getTime() }
  })
}
export function checkWxCodeResult (params) {
  return request({
    url: API.checkWxCodeResult,
    method: 'get',
    params: { ...params, _t: new Date().getTime() }
  })
}
export function userLogin (params) {
  return request({
    url: API.userLogin,
    method: 'post',
    params: { ...params, _t: new Date().getTime() }
  })
}
export function getPermission () {
  return request({
    url: API.getPermission,
    method: 'get',
    params: { _t: new Date().getTime() }
  })
}
export function getSmsCode (params) {
  return request({
    url: API.getSmsCode,
    method: 'get',
    params: {
      ...params,
      _t: new Date().getTime()
    }
  })
}
export function getFactoryAccountList (params) {
  return request({
    url: API.getFactoryAccountList,
    method: 'get',
    params
  })
}
export function onCheckFactoryAccount (factoryId = '') {
  return request({
    url: API.onCheckFactoryAccount,
    method: 'get',
    params: { factoryId }
  })
}
export function setNormalAccount (params) {
  return request({
    url: API.setNormalAccount,
    method: 'get',
    params
  })
}
export function getAccountInfo () {
  return request({
    url: API.getAccountInfo,
    method: 'get',
    params: { _t: new Date().getTime() }
  })
}
export function getAccountScore () {
  return request({
    url: API.getAccountScore,
    method: 'get'
  })
}
export function getLoginRecord (params) {
  return request({
    url: API.getLoginRecord,
    method: 'get',
    params
  })
}
export function getSensitiveRecord (params) {
  return request({
    url: API.getSensitiveRecord,
    method: 'get',
    params
  })
}
export function bindMobile (params) {
  return request({
    url: API.bindMobile,
    method: 'post',
    params
  })
}
export function changeMobile (params) {
  return request({
    url: API.changeMobile,
    method: 'get',
    params
  })
}
export function changePwd (params) {
  return request({
    url: API.changePwd,
    method: 'post',
    params
  })
}
export function setNewPassword (params) {
  return request({
    url: API.setNewPassword,
    method: 'post',
    params
  })
}
export function paymentCheckValue (params) {
  return request({
    url: API.paymentCheckValue,
    method: 'post',
    params
  })
}
export function paymentLimit (params) {
  return request({
    url: API.paymentLimit,
    method: 'post',
    params
  })
}
export function bindWechat (params) {
  return request({
    url: API.bindWechat,
    method: 'get',
    params
  })
}
export function userMobileLogin (params) {
  return request({
    url: API.userMobileLogin,
    method: 'get',
    params: { ...params, _t: new Date().getTime() }
  })
}
export function getAccountsRoles () {
  return request({
    url: API.getAccountsRoles,
    method: 'get'
  })
}
export function checkCaptcha (params) {
  return request({
    url: API.checkCaptcha,
    method: 'get',
    params
  })
}
