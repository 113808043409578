import actions from './action'
import mutations from './mutation'

const state = {
  mode: '', // camera 摄像头模式, file 课件模式, share 屏幕分享模式
  deviceInfo: {
    speaker: {},
    camera: {},
    microphone: {}
  },
  controllers: {
    muteMic: false,
    muteCamera: false
  },
  layout: {
    type: '', // custom 自定义布局, normal 推荐布局, discussion 讨论模式
    templateImg: '' // 自定义布局模板图
  },
  connectionMembers: [],
  groupLiveList: [],
  streamPublishStatus: 'unPublish', // 推流状态 unPublish-未推流  publishing-正在推流  loading-验证设备条件/接口回调中
  liveStatus: '', // 直播状态：0 即将开始 1 直播中 2 录播处理中 3 直播回放
  liveInfo: {},
  roomInfo: {},
  connectApplyWaiting: false
}
const getters = {
  groupLiveList: state => state.groupLiveList,
  liveStatus: state => state.liveStatus,
  liveActive: state => {
    const n = parseFloat(state.liveStatus)
    const status = isNaN(n) ? '' : n
    return status === 0 || status === 1 || status === 4
  },
  liveEnd: state => {
    const n = parseFloat(state.liveStatus)
    const status = isNaN(n) ? '' : n
    return status && status > 1
  },
  isLiving: state => {
    const n = parseFloat(state.liveStatus)
    const status = isNaN(n) ? '' : n
    return status === 1
  },
  deviceInfo: state => state.deviceInfo,
  controllers: state => state.controllers,
  mode: state => state.mode,
  hasDevices: ({ deviceInfo: { camera, microphone } }) => camera.deviceId && microphone.deviceId,
  layout: state => state.layout,
  isPublishing: state => state.streamPublishStatus === 'publishing',
  isPublishLoading: state => state.streamPublishStatus === 'loading',
  liveInfo: state => state.liveInfo,
  isMainSpeaker: state => {
    const { userInfo, mainSpeakerUserId, speakerType } = state.liveInfo
    return userInfo?.id === mainSpeakerUserId || (!mainSpeakerUserId && speakerType === 0)
  },
  isMainSpeakerOrHost: state => {
    const { userInfo, mainSpeakerUserId, speakerType } = state.liveInfo
    return userInfo?.id === mainSpeakerUserId || speakerType === 0
  },
  isWebinarPresenter: state => {
    const { userInfo, speakers } = state.liveInfo
    return speakers.filter(member => {
      return member.userId === userInfo
    })
  },
  isWebinar: state => state.liveInfo?.sceneType === 6,
  isSingleLive: state => state.liveInfo?.sceneType === 5,
  isHost: state => state.liveInfo?.speakerType === 0,
  templateImg: state => state.layout.templateImg,
  webinarMembers: state => state.liveInfo?.speakers || [],
  isConnectionMember: state => state.connectionMembers.filter(member => member.userId === state.liveInfo?.userInfo?.id).length,
  connectApplyWaiting: state => state.connectApplyWaiting
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
