import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    alias: ['', '/index'],
    name: 'Index',
    component: () => import('@/views/index/Index'),
    meta: {
      requiresAuth: true,
      title: '首页'
    }
  },
  {
    path: '/live/detail/:ticket',
    name: 'LiveDetail',
    component: () => import('@/views/live/LiveDetail'),
    meta: {
      requiresAuth: false,
      title: '直播详情'
    }
  },
  {
    path: '/user/login',
    name: 'UserLogin',
    component: () => import('@/views/user/UserLogin'),
    meta: {
      requiresAuth: false,
      title: '登录'
    }
  },
  {
    path: '/live/watch/:liveId',
    name: 'LiveWatch',
    component: () => import('@/views/live/LiveWatch'),
    meta: {
      requiresAuth: false,
      title: '直播观看'
    }
  },
  {
    path: '/live/single-player/:ticket',
    name: 'LiveRoom',
    component: () => import('@/views/live/singlePlayer/Index'),
    meta: {
      requiresAuth: false,
      title: '直播',
      customHeader: true
    }
  },
  {
    path: '/live/end/:ticket',
    name: 'LiveEnd',
    component: () => import('@/views/live/singlePlayer/LiveEnd'),
    meta: {
      requiresAuth: false,
      title: '直播结束',
      noHeader: true
    }
  },
  {
    path: '/live/webinar/:ticket',
    name: 'WebinarRoom',
    component: () => import('@/views/live/webinar/Index'),
    meta: {
      requiresAuth: false,
      title: '多人会议',
      customHeader: true
    }
  },
  {
    path: '/live/webinar-v2/:ticket',
    name: 'WebinarRoomV2',
    component: () => import('@/views/WebinarV2/Index.vue'),
    meta: {
      requiresAuth: false,
      title: '多人会议2.0',
      noHeader: true
    }
  },
  {
    path: '/live/webinar-v2/:ticket/feedback',
    name: 'WebinarV2Feedback',
    component: () => import('@/views/WebinarV2/Feedback.vue'),
    meta: {
      requiresAuth: false,
      title: '会议反馈',
      noHeader: true
    }
  },
  {
    path: '/help-center/:ticket',
    name: 'HelpCenter',
    component: () => import('@/views/HelpCenter/layouts'),
    meta: {
      requiresAuth: false,
      title: '帮助中心',
      noHeader: true
    },
    children: [
      {
        path: '/help-center/:ticket/list/:categoryId',
        name: 'ArticleList',
        component: () => import('@/views/HelpCenter/ArticleList.vue'),
        meta: {
          requiresAuth: false,
          title: '文章列表',
          noHeader: true
        }
      },
      {
        path: '/help-center/:ticket/list/:categoryId/detail/:id',
        name: 'ArticleDetail',
        component: () => import('@/views/HelpCenter/ArticleDetail.vue'),
        meta: {
          requiresAuth: false,
          title: '文章详情',
          noHeader: true
        }
      },
      {
        path: '/help-center/:ticket/feedback',
        name: 'Feedback',
        component: () => import('@/views/HelpCenter/Feedback.vue'),
        meta: {
          requiresAuth: false,
          title: '问题反馈',
          noHeader: true
        }
      }
    ]
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router
