/*
 * @Description:In User Settings Edit
 * @Author:your name
 * @Date:2019-09-26 13:40:16
 * @LastEditTime: 2021-11-18 18:23:44
 * @LastEditors: Please set LastEditors
 */
// const API_BASE_V2 = '/qxs-api'
// const API_BASE_V1 = '/ksh-api'
const devServerLastIp = '35'
const devEnvPrefix = devServerLastIp ? `${devServerLastIp}-` : ''
const API_BASE_V2 = require('../../config/index').isDev ? `/gateway/${devEnvPrefix}qxs-api` : '/gateway/qxs-api'
export default {
  // 个人信息
  userLogin: `${API_BASE_V2}/factory/v1/auth/login`, // 厂家端登录
  getUserInfo: `${API_BASE_V2}/common/web-client/user-info`,
  // getFactoryAccountList: `${API_BASE_V2}/represent/v1/person/factories`, // 个人中心更改厂家 厂家列表 不需要参数
  getFactoryAccountList: `${API_BASE_V2}/represent/search/top-factory`, // 个人中心更改厂家 厂家列表 不需要参数
  onCheckFactoryAccount: `${API_BASE_V2}/represent/v1/person/select-factory`, // 个人中心更改厂家 确定选择 params:{factoryId}
  getWxCodeImage: `${API_BASE_V2}/public/v1/pc/wx-qrcode`, // 微信扫码登录二维码 0医生登陆直播PC讲者端;1组织登录;2微信绑定；3微信换绑；4敏感支付操作 5 代表登陆
  checkWxCodeResult: `${API_BASE_V2}/public/v1/pc/scan-login-check`, // 微信扫码登录查询结果
  userMobileLogin: `${API_BASE_V2}/public/v1/mobile/login`, // 验证码登录
  uploadImage: `${API_BASE_V2}/common/v1/upload-image`, // 上传图片
  // 首页 列表
  getGroupLiveList: `${API_BASE_V2}/common/web-client/list`, // 获取用户的会议列表
  getLiveInfoByTicket: `${API_BASE_V2}/public/web-client/info-by-ticket`, // 使用ticket凭证换取直播信息以及用户信息
  changeLiveStatusByTicket: `${API_BASE_V2}/public/web-client/change-status-by-ticket`, // 使用ticket凭证改变会议状态
  getWatchUrl: `${API_BASE_V2}/public/web-client/watch-url`, // 使用liveId获取微吼会议观看地址
  // 厂家端 账户管理
  bindMobile: `${API_BASE_V2}/common/v1/mobile/bind`, // 绑定手机号
  changeMobile: `${API_BASE_V2}/common/v1/mobile/change`, // 更换手机号
  changePwd: `${API_BASE_V2}/factory/v1/auth/modify-password`, // 修改密码（验证老密码）
  setNewPassword: `${API_BASE_V2}/factory/v1/login/new-password`, // 设置新密码（忘记密码、验证手机号修改密码）
  paymentCheckValue: `${API_BASE_V2}/org/safety-center/get-check-value`, // 支付限额校验验证码并获取凭证
  paymentLimit: `${API_BASE_V2}/org/safety-center/modify-pay-limit`, // 修改厂家支付限额
  checkCaptcha: `${API_BASE_V2}/public/captcha/v1/check`, // 检查人机校验
  bindWechat: `${API_BASE_V2}/factory/v1/bind-wx`, // 绑定或换绑微信
  // 直播 课件管理
  getCurrentPublishUserId: `${API_BASE_V2}/public/web-client/get-live-push-user`, // 获取当前推流的用户id
  getCoursewareList: `${API_BASE_V2}/public/web-client/courseware-list`, // 获取课件列表
  uploadFileByTicket: `${API_BASE_V2}/public/web-client/upload-ticket`, // 上传文件
  transcodeCourseware: `${API_BASE_V2}/public/web-client/upload-courseware`, // 课件转码
  deleteCourseware: `${API_BASE_V2}/public/web-client/remove-courseware`, // 删除课件
  uploadTemplateImg: `${API_BASE_V2}/public/web-client/upload-template-img`, // 上传模板图片
  liveModeChange: `${API_BASE_V2}/public/web-client/live-mode-change`, // 直播窗口类型模式切换
  applyOnline: `${API_BASE_V2}/public/web-client/apply-for-on-line`, // 嘉宾申请上麦
  setConnectionStatus: `${API_BASE_V2}/public/web-client/set-speaker-status`, // 操作视频连线状态
  setMainSpeaker: `${API_BASE_V2}/public/web-client/set-main-speaker`, // 主持人设置主讲人
  getConnectionMembers: `${API_BASE_V2}/public/web-client/pushing-user-info`, // 获取当前视频连线的嘉宾列表
  updateNetworkQuality: `${API_BASE_V2}/public/web-client/update-network-quality`, // 定时上传网络状况
  getUserSig: `${API_BASE_V2}/public/web-client/getUserSig`, // 测试用户获取usersig
  setStreamPushSuccess: `${API_BASE_V2}/public/web-client/stream-push-success`, // 推流成功链路检查
  getLiveStatus: `${API_BASE_V2}/public/web-client/get-live-status`,
  reportStartPushStream: `${API_BASE_V2}/public/web-client/start-stream-push`,
  reportPushStreamStatus: `${API_BASE_V2}/public/web-client/stream-push-report`, // 上报推流状态
  getLiveDuration: `${API_BASE_V2}/public/web-client/getLiveDuration`,
  reportStreamQuality: `${API_BASE_V2}/public/web-client/stream-quality-report`, // 上报流质量
  // 会议
  // 全员禁音
  setWebinarForbidden: `${API_BASE_V2}/public/web-client/set-webinar-forbidden`,
  // 设置联席主持人
  setCoPresent: `${API_BASE_V2}/public/web-client/set-co-present`,
  // 设置课件权限
  setCoursewarePermission: `${API_BASE_V2}/public/web-client/courseware-permission`,
  // 保存反馈
  setFeedback: `${API_BASE_V2}/public/web-client/ticket-webinar-questionnaire`,
  // 设置共享权限
  setShareControl: `${API_BASE_V2}/public/web-client/set-webinar-share-permission`,
  // 切换视图
  setWebinarLayout: `${API_BASE_V2}/public/web-client/set-webinar-layout`,
  // 帮助中心
  // 获取分类列表
  getHelpCenterCategoryList: `${API_BASE_V2}/public/web-client/article/v1/category-list`,
  // 保存帮助中心反馈
  setHelpCenterFeedback: `${API_BASE_V2}/public/web-client/article/public/web-client/ticket-webinar-feedback`,
  // 获取文章列表
  getArticleList: `${API_BASE_V2}/public/web-client/article/v1/list`,
  // 获取文章详情
  getArticleDetail: `${API_BASE_V2}/public/web-client/article/v1/detail`,
  // 文章点赞和取消
  setArticleMark: `${API_BASE_V2}/public/web-client/article/v1/mark`,
  // 获取会议信息
  getWebinarInfo: `${API_BASE_V2}/public/web-client/live-info-by-ticket`
}
export const rtc = {
  getRtcAnswerSdp: 'https://webrtcpush.myqcloud.com/webrtc/v1/pushstream', // 腾讯云webrtc流请求地址
  stopRtcStream: 'https://webrtcpush.myqcloud.com/webrtc/v1/stopstream'// 腾讯云webrtc断流
}
export const tim = {
  getLiveOldMessageList: `${API_BASE_V2}/public/web-client/msg-list-ticket`, // 历史消息列表
  dealChatMsg: `${API_BASE_V2}/public/web-client/deal-chat-msg-ticket`, // 处理直播间消息
  getBlackLiveList: `${API_BASE_V2}/public/web-client/black-list-ticket`, // 直播的黑名单
  handleBlackList: `${API_BASE_V2}/public/web-client/black-list-deal-ticket`, // 移出加入直播的黑名单
  setChatRoomForbidden: `${API_BASE_V2}/public/web-client/set-chat-room-forbidden-ticket`, // 禁言
  liveDetail: `${API_BASE_V2}/doctor/speaker/v1/live-detail` // 获取讲者端直播间信息
}
export const oss = {
  getOssAutograph: `${API_BASE_V2}/public/web-client/get-autograph` // 获取ali-oss key
}
