/* eslint-disable */
// import CryptoJS from 'crypto-js'
import XLSX from 'xlsx'

const CryptoJS = require('crypto-js')

/**
 * 页面滚动到指定element ID处
 * @param targetId: 页面元素的ID
 * @return null
 */
export function scrollToElementId (targetId) {
  if (window && document) {
    document.getElementById(targetId).scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }
}

/**
 * int 分单位转换为元单位
 * @param fen(int):
 * @return (int)
 */
export const fenToYuan = (fen) => {
  const num = fen + ''
  const intNum = num.slice(0, -2)
  const dotNum = num.slice(-2)
  const lastNum = num.slice(-1)
  if (Number(dotNum) > 0) {
    if (Number(lastNum) === 0) {
      return intNum + '.' + dotNum.slice(0, 1)
    }
    return intNum + '.' + dotNum
  }
  return Number(intNum)
}
/**
 * int 分单位转换为整数的元单位（int）
 * @param fen(int):
 * @return (int)
 */
export const fenToYuanInt = (fen) => {
  const num = fen + ''
  const intNum = num.slice(0, -2) // 整数位
  return Number(intNum)
}
/**
 * int 元转分
 * @param yuan(int):
 * @return (int)
 */
export const YuanToFen = (yuan) => {
  return yuan * 100
}
/**
 * 某个对象是否在数组里,并返回index
 * @param arr(array):
 * @param key(string):
 * @param val:
 * @return index (int)
 */
export const objIndexOf = (arr, key, val) => {
  let index = -1
  arr.forEach((item, ind) => {
    if (item[key] === val) {
      index = ind
    }
  })
  return index
}

/**
 * 从url中获取参数
 * @param variable string:
 * @return string
 */
export function getQueryVariable (variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return (false)
}

/**
 * 人际验证注入js
 */
export function initTCaptcha () {
  return new Promise((resolve, reject) => {
    if (window.TencentCaptcha) {
      resolve(true)
    } else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://ssl.captcha.qq.com/TCaptcha.js'
      script.onerror = reject
      script.onload = function () {
        resolve(true)
      }
      document.head.appendChild(script)
    }
  })
}

/***
 * 邀请码类型
 * @params type string: meeting->科会；questionnaire->调研；live->直播；
 * @return string
 *
 **/
const sceneTypeMap = new Map([
  [1, [1, '科会', 'meeting']],
  ['1', [1, '科会', 'meeting']],
  ['meeting', [1, '科会', 'meeting']],
  ['科会', [1, '科会', 'meeting']],
  [4, [4, '调研', 'questionnaire']],
  ['4', [4, '调研', 'questionnaire']],
  ['survey', [4, '调研', 'questionnaire']],
  ['questionnaire', [4, '调研', 'questionnaire']],
  ['调研', [4, '调研', 'questionnaire']],
  [5, [5, '直播', 'live']],
  ['5', [5, '直播', 'live']],
  ['live', [5, '直播', 'live']],
  ['直播', [5, '直播', 'live']]
])
export const inviteCodeType = (type) => {
  if (!sceneTypeMap.get(type)) {
    return sceneTypeMap.get(1)
  }
  return sceneTypeMap.get(type)
}

/**
 * 检查密码强度
 * @param {String} val 密码
 * @return Object {value,key}
 */
export function checkPassWordStrength (val) {
  // 密码安全度，高：包含大写字母、小写字母、数字和特殊符号；中：仅包含高中的三种；低：包含不到三种；高危：只有一种；
  let modes = 0
  const obj = {
    1: { value: '高危', key: 0 },
    2: { value: '安全度低', key: 1 },
    3: { value: '安全度中', key: 2 },
    4: { value: '安全度高', key: 3 }
  }
  if (/\d/.test(val)) modes++ // 数字
  if (/[a-z]/.test(val)) modes++ // 小写
  if (/[A-Z]/.test(val)) modes++ // 大写
  if (/\W/.test(val)) modes++ // 特殊字符
  return obj[modes] || {}
}

/**
 * AES加密
 * @param {any} word 加密数据
 */
export function cryptoEncrypt (word) {
  if (!word) return ''
  const config = {
    AES_KEY: 'QuXueShu20201230',
    AES_IV: 'QuXueShu20201230'
  }
  var key = CryptoJS.enc.Utf8.parse(config.AES_KEY)
  var iv = CryptoJS.enc.Utf8.parse(config.AES_IV)
  var encrypted = ''
  var srcs = ''
  if (typeof (word) === 'string') {
    srcs = word
  } else if (typeof (word) === 'object') { // 对象格式的转成json字符串
    srcs = CryptoJS.enc.Utf8.parse(word)
  }
  encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

/**
 * 导出为excel
 */
export function JsonToExcel (title = '', json = null, merge) {
  try {
    if (!json) return
    // 创建worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(json)
    if (merge) {
      worksheet['!merges'] = merge
    }
    // 新建空workbook，加入worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, '')
    XLSX.writeFile(workbook, `${title}.xlsx`)
  } catch (e) {
    Promise.reject('数据导出失败')
  }
}

export function isSafariBrowser () {
  const str = window.navigator.appVersion.toLowerCase()
  const re = new RegExp(/(\d+\.\d)?(?:\.\d)?\s+safari\/?(\d+\.\d+)?/i)
  return re.test(str)
}

export function isChromeBrowser () {
  const str = window.navigator.appVersion.toLowerCase()
  const re = new RegExp(/chrome\/(\d+\.\d+)/i)
  return re.test(str)
}

export function downloadChromeUrl () {
  window.open('https://www.google.cn/chrome/')
}
