<!--
 * @Author: your name
 * @Date: 2021-03-03 21:34:51
 * @LastEditTime: 2021-03-19 13:12:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-group-live/src/layout/Layout.vue
-->
<template>
  <el-container direction="vertical">
    <!--chrome浏览器支持条幅-->
    <el-alert type="warning" :closable="false" v-if="showChromeDownload" center>
      <template #title>
        <div class="title-wrap">
          <span>您未使用系统推荐的谷歌浏览器（或浏览器版本过低），可能会因浏览器兼容性问题而导致直播问题，影响直播系统功能及稳定性。建议使用最新版本谷歌浏览器进行直播和操作。</span>
          <el-link type="primary" class="link" href="javascript:void(0)" @click="downloadChrome">下载谷歌浏览器</el-link>
        </div>
      </template>
    </el-alert>
    <Header class="media-layout" v-if="showPage && !$route.meta.noHeader" />
    <el-main class="media-layout">
      <slot v-if="showPage"></slot>
      <div v-else>
        <el-row type="flex" class="wrap-layout" justify="center" align="middle">请在Chrome或Safari浏览器中打开 </el-row>
        <el-row type="flex" class="wrap-layout" justify="center" align="middle"> 会议地址：https://live.quxueshu.com </el-row>
      </div>
    </el-main>
    <div class="mobile-layout">
      <el-row type="flex" align="middle" class="address-box">
        <span class="address-title">会议地址</span>
        <div class="address">{{ url }}</div>
        <span class="mark copy" @click="onCopy">点击复制</span>
      </el-row>
      <el-row class="address-box">
        操作流程：<br />
        第一步：点击复制链接<br />
        第二步：将链接复制到Safari浏览器或者Chrome（谷歌）浏览器—选择您的身份（医生或代表）<br />
        第三步：使用微信扫一扫登录<br />
        第四步：选择会议，点击进入会议<br />
        第五步：调试会议，开始会议
      </el-row>
      <el-row class="address-box">
        注意事项：<br />
        <span>1.请使用<span class="mark">Chrome（谷歌）浏览器</span>或<span class="mark">Safari浏览器</span> 打开会议地址，并且保障您的flashplayer版本在11.4以上。<br /></span>
        2.要求主持人和嘉宾上下行带宽50M以上。<br />
        3.上传会议文档不能超过200页，大小不超过100M。<br />
        4.会议进行中，请尽可能避免更换硬件设备、刷新网页等操作。
      </el-row>
    </div>
  </el-container>
</template>
<script>
import Header from './Header'
import { isSafariBrowser, isChromeBrowser, downloadChromeUrl } from '@/utils/util'
import copy from 'copy-to-clipboard'

export default {
  name: 'Layout',
  data() {
    return {
      showPage: false,
      url: window.location.href
    }
  },
  components: {
    Header
  },
  computed: {
    notChrome() {
      return this.$store.state.common.isChrome
    },
    showChromeDownload() {
      return this.$route.name === 'LiveRoom' && !this.$store.state.common.isChrome
    }
  },
  methods: {
    onCopy() {
      if (copy(this.url)) {
        this.$message.success('链接复制成功')
      } else {
        this.$message.error('链接复制失败')
      }
    },
    downloadChrome() {
      downloadChromeUrl()
    }
  },
  mounted() {
    this.showPage = isSafariBrowser() || isChromeBrowser()
  }
}
</script>
<style lang="scss" scoped>
.title-wrap{
  display: flex;
  align-items: center;
  .span {
    flex: 1;
  }
  .el-link {
    flex-shrink: 0;
  }
}
.btn-wrap {
  width: 150px;
  margin-top: 8vw;
  color: #218cff;
}

.wrap-layout {
  font-size: 16px;
  margin-bottom: 20px;
}

.mobile-layout {
  height: 100%;
  padding: 0 3vw;
  background-image: url('../assets/common/mobile_bg_cover.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  color: #303133;
}

.el-main {
  background: #f6f7fb;
}

@media screen and (max-width: 700px) {
  .media-layout {
    display: none;
  }
}

@media screen and (min-width: 750px) {
  .mobile-layout {
    display: none;
  }
}

.address-box {
  background: #ffffff;
  border-radius: 8px;
  opacity: 0.74;
  padding: 12px 9px;
  margin-top: 10px;
}

.address-title {
  font-weight: bold;
  padding-right: 10px;
}

.address {
  // width: 180px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mark {
  color: #198cff;
}

.copy {
  padding-left: 10px;
  font-weight: bold;
}
</style>
<style lang="scss" scoped>
.link {
  margin-left: 10px;
  font-size: 14px;
}

:deep(.el-alert--warning) {
  color: #ff7600;
  background-color: #fff3de;
}
</style>
