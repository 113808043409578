import actions from './action'
import mutations from './mutation'

const state = {
  microphone: { id: '', mute: false },
  camera: { id: '', mute: false, mirror: true, beauty: false },
  speaker: { id: '', audioVolume: 100 },
  roomConfig: {
    id: '',
    title: '',
    isAllMuteAudio: false,
    // 0 全员共享 1 主持人共享
    shareControl: 0,
    needQuestionnaire: true
  },
  userConfig: {
    userId: '',
    identity: 1,
    name: '',
    avatarUrl: '',
    // 视图模式 0 九宫 1 演讲
    viewLayout: 0
  },
  // 嘉宾列表 {userId, userHead, userType, stream, muteAudio, muteVideo, volume}
  guestList: [],
  // 共享屏幕的信息
  shareInfo: {
    stream: null,
    userId: '',
    identity: 1,
    coursewareUrl: '',
    isMe: true
  },
  isFullScreen: false
}
const getters = {
  microphoneDeviceId: state => state.microphone.id,
  isMuteAudio: state => state.microphone.mute,
  cameraDeviceId: state => state.camera.id,
  deviceInfo: state => (type) => state[type],
  isMuteVideo: state => state.camera.mute,
  guestList: state => state.guestList,
  onlineGuestList: state => state.guestList.filter(item => item.stream),
  offlineGuestList: state => state.guestList.filter(item => !item.stream),
  isInGuestList: state => (userId) => state.guestList.some(item => item.userId === userId),
  shareInfo: state => state.shareInfo,
  getUserInfoByUserId: state => (userId) => {
    let userInfo = {}
    state.guestList.forEach(item => {
      if (item.userId === userId) {
        userInfo = item
      }
    })
    return userInfo
  },
  roomConfig: state => state.roomConfig,
  userConfig: state => state.userConfig,
  hasOnlineChair: state => !!state.guestList.filter(item => item.stream && item.identity === 0 && !item.isMe).length,
  hasOnlineJointChair: state => !!state.guestList.filter(item => item.stream && item.identity === 3 && !item.isMe).length,
  onlineContributor: state => state.guestList.filter(item => item.stream && !item.isMe && item.identity === 1),
  isFullScreen: state => state.isFullScreen
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
