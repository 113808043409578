import router from './index'
import { setToken, getToken, removeToken } from '@/utils/auth'
import { setUid } from '@/utils/ali-log'

router.beforeEach((to, from, next) => {
  const AUTH_TOKEN = to.query.token || ''
  if (AUTH_TOKEN) {
    setToken(AUTH_TOKEN)
    delete to.query.token
    next({ ...to, replace: true })
  }
  if (to.matched.length === 0) {
    next({ path: '/index' })
  } else if (to.name === 'UserLogin') {
    removeToken()
    setUid('')
  }
  if (to.matched.some(record => record.meta.requiresAuth) && !getToken()) {
    const { type, ticket } = to.query
    if (type === 'webinar' && ticket) {
      next()
    } else {
      next({
        path: '/user/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})
