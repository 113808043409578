import { createStore, useStore } from 'vuex'
import { computed, reactive, toRef } from 'vue'
import common from './module/common'
import index from './module/index'
import user from './module/user'
import live from './module/live'
import tim from './module/tim'
import webinar from './module/webinar'

const debug = process.env.NODE_ENV !== 'production'
export default createStore({
  modules: {
    common,
    index,
    user,
    live,
    tim,
    webinar
  },
  strict: debug
})
export const useGetters = (moduleName = '', keyArray = []) => {
  // 该方法返回的proxyGetters对象只能通过computed使用，且不能解构
  if (moduleName && Array.isArray(moduleName)) {
    keyArray = moduleName
    moduleName = ''
  }
  const modulePath = moduleName ? `${moduleName}/` : ''
  const { getters } = useStore()
  const moduleKeys = Object.keys(getters).filter(key => key.indexOf(modulePath) > -1)
  if (keyArray.length === 0) keyArray = moduleKeys.map(fullKey => fullKey.replace(modulePath, ''))
  // 代理模式
  const proxyGetters = new Proxy({}, {
    get (obj, prop) {
      let value = {}
      if (keyArray.includes(prop)) {
        value = getters[modulePath + prop]
      }
      return value
      // return value ? toRef(getters, modulePath + prop) : undefined // 解构支持
    },
    set () {
      this.readOnly()
    },
    defineProperty () {
      this.readOnly()
    },
    readOnly () {
      console.warn('proxy getter is readonly')
    }
  })
  // 默认模式
  // const moduleKeys = Object.keys(getters).filter(key => key.indexOf(modulePath) > -1)
  // const proxyGetters = {}
  // keyArray
  //   .forEach(key => {
  //     const fullKey = modulePath + key
  //     if (moduleKeys.includes(fullKey)) {
  //       // moduleGetters[key] = toRef(getters, fullKey) // 解构支持
  //       // 经典defineProperty模式
  //       Object.defineProperty(proxyGetters, key, {
  //         get: () => getters[fullKey],
  //         enumerable: true
  //       })
  //     }
  //   })
  return proxyGetters
}
export const useActions = (moduleName = '', keyArray = []) => {
  if (moduleName && Array.isArray(moduleName)) {
    keyArray = moduleName
    moduleName = ''
  }
  const modulePath = moduleName ? `${moduleName}/` : ''
  const store = useStore()
  const moduleKeys = Object.keys(store._actions).filter(key => key.indexOf(modulePath) > -1)
  const proxyActions = {}
  if (keyArray.length === 0) keyArray = moduleKeys.map(fullKey => fullKey.replace(modulePath, ''))
  keyArray
    .forEach(key => {
      const fullKey = modulePath + key
      if (moduleKeys.includes(fullKey)) {
        proxyActions[key] = async (payload, options) => {
          return await store.dispatch(fullKey, payload, options)
        }
      }
    })
  return proxyActions
}
export const useMutations = (moduleName = '', keyArray = []) => {
  if (moduleName && Array.isArray(moduleName)) {
    keyArray = moduleName
    moduleName = ''
  }
  // if (keyArray.length === 0) {
  //   console.warn('mutations array is required')
  //   return {}
  // }
  const modulePath = moduleName ? `${moduleName}/` : ''
  const store = useStore()
  const moduleKeys = Object.keys(store._mutations).filter(key => key.indexOf(modulePath) > -1)
  const proxyMutations = {}
  if (keyArray.length === 0) keyArray = moduleKeys.map(fullKey => fullKey.replace(modulePath, ''))
  keyArray
    .forEach(key => {
      const fullKey = modulePath + key
      if (moduleKeys.includes(fullKey)) {
        proxyMutations[key] = (payload, options) => {
          return store.commit(fullKey, payload, options)
        }
      }
    })
  return proxyMutations
}
export const useNamedStore = (moduleName) => {
  const store = useStore()
  const { state } = store
  if (!state?.[moduleName]) {
    console.warn('did not found module ' + moduleName)
    return false
  }
  return {
    state: state[moduleName],
    getters: useGetters(moduleName),
    mutations: useMutations(moduleName),
    actions: useActions(moduleName)
  }
}
