/*
 * @Author: your name
 * @Date: 2020-10-30 14:55:06
 * @LastEditTime: 2020-12-23 14:36:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/config/index.js
 */
module.exports = {
  assetsCdnPath: 'https://cdnfile.yaomaitong.cn/ksh/',
  assetsPublicPath: '/', // new added to resoved nup run build icon can not be shown issue
  hasCdn: process.env.CDN === 'true',
  isDev: process.env.NODE_ENV === 'development'
}
