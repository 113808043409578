import * as Types from '../../mutations-types'
import { DefaultUserMenu } from '../../constance'

export default {
  [Types.HIDE_USER_MENU] (state) {
    state.dynamicUserMenu = []
  },
  [Types.SET_DYNAMIC_USER_MENU] (state, menu = []) {
    state.dynamicUserMenu = [...menu]
  },
  [Types.RESET_DYNAMIC_USER_MENU] (state) {
    state.dynamicUserMenu = [...DefaultUserMenu]
  }
}
