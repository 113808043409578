/*
 * @Author: your name
 * @Date: 2020-10-28 17:46:02
 * @LastEditTime: 2021-02-26 11:54:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/common/index.js
 */
import actions from './action'
import mutations from './mutation'
import { DefaultUserMenu } from '../../constance'

const state = {
  dynamicUserMenu: [...DefaultUserMenu]
}
const getters = {
  dynamicUserMenu: state => state.dynamicUserMenu
}
export default {
  state,
  getters,
  actions,
  mutations
}
