<!--
 * @Author: your name
 * @Date: 2020-12-17 16:35:28
 * @LastEditTime: 2021-03-05 16:28:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/bizComponents/SelectFactoryAccountModal.vue
-->
<template>
  <el-dialog
    :model-value="visible"
    title="切换厂家"
    :show-close="false"
    class="factory-modal"
    width="500px"
    @close="$emit('close:dialog')"
  >
    <div class="scroll-list" v-loading="loading">
      <div
        :class="['list-item flex vertical-center pointer',{'active':item.id === selectedItem.id}]"
        v-for="item in factoryAccountList"
        :key="item.name"
        @click="onCheck(item)"
      >
        <div class="flex-box">{{item.name}}</div>
        <div class="flex-shrink list-item-radio flex level-center vertical-center">
          <i class="el-icon-check" v-if='item.id === selectedItem.id'></i>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button plain @click="$emit('close:dialog')">取消</el-button>
        <el-button type="primary" @click="onSubmit" :loading='isSubmit'>确认切换</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SelectFactoryAccountModal',
  data () {
    return {
      loading: false,
      selectedItem: {},
      isSubmit: false
    }
  },
  props: ['visible'],
  emits: ['close:dialog'],
  computed: {
    ...mapGetters(['factoryAccountList', 'userInfo'])
  },
  methods: {
    ...mapActions(['getFactoryAccountList', 'onCheckFactoryAccount']),
    init () {
      // 初始化默认选择
      this.selectedItem = { id: this.userInfo.orgId, name: this.userInfo.orgName }
      this.loading = true
      this.getFactoryAccountList()
        .then(res => {
          this.loading = false
        }, rea => {
          this.loading = false
        })
    },
    onCheck (item) {
      this.selectedItem = item
    },
    onSubmit () {
      if (this.selectedItem.id === this.userInfo.orgId) return this.$message.error('请勿选择相同的厂家')
      if (this.loading || this.isSubmit) return
      this.isSubmit = true
      this.onCheckFactoryAccount(this.selectedItem)
        .then(res => {
          this.isSubmit = false
          this.$emit('close:dialog')
        }, rea => {
          this.isSubmit = false
          this.$message.error(rea.message)
        })
      // let checkItem = {}
      // checkItem = this.factoryAccountList.filter(item=>item.current)[0]
      // if(!checkItem.id) return this.$message.error('请选择切换的账号')
      // this.$emit('onSubmit',checkItem)
    }
  },
  watch: {
    visible (open) {
      if (open) {
        this.init()
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="stylus" scoped>
  color-primary = #198CFF
  .el-button
    width 150px

  .factory-modal :v-deep .el-dialog__body
    padding 0
    height 360px
    display flex
    flex-direction column

  .factory-modal :v-deep .el-dialog__footer
    padding 30px 70px
    display flex
    justify-content center

  .title
    color #272E40
    font-size 24px
    flex-shrink 0
    padding 30px 40px 30px

  .scroll-list
    flex 1
    overflow-y auto
    padding 0 40px

  .list-item
    color #272E40
    font-size 14px
    padding 32px 0
    border-bottom 1px solid #E9EAEB

    &.active
      color color-primary

      .list-item-radio
        background color-primary
        border 0

  .list-item:last-child
    border-bottom none

  .list-item-radio
    margin-left 40px
    width 24px
    height 24px
    border-radius 50%
    border 1px solid #C5C5C5
    background #F8F8F8
    .el-icon-check
      color white
</style>
