const BrowserLogger = require('alife-logger')

function getEnv() {
  let env = 'prod'
  const domain = window.location.host
  if (domain.indexOf('localhost') > -1) {
    env = 'local'
  } else if (/live-dev/g.test(domain)) {
    env = 'daily'
  } else if (domain.indexOf('live.yaomaitong-pre.cn') > -1) {
    env = 'pre'
  }
  return env
}
// BrowserLogger.singleton(conf) conf传入config配置。
const __bl = BrowserLogger.singleton({
  pid: 'b7z525alis@9f11e300d9be6a7',
  appType: 'web',
  imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
  sendResource: true,
  enableLinkTrace: true,
  behavior: true,
  environment: getEnv(),
  ignore: {
    ignoreApis: [(url) => {
      if (url.indexOf('yun.tim.qq.com') > -1) {
        return true
      }
    }]
  }
})

export function parseUid (token) {
  let uid
  if (token) {
    const strs = token.split('_')
    uid = strs[1]
  }
  return uid
}

export function setUid(uid) {
  __bl.setConfig({
    uid
  })
}

export function setUsername(username) {
  __bl.setConfig({
    setUsername: function () {
      return username || null
    }
  })
}

export default __bl
