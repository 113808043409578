import { TIM, emitter } from '@/views/tim/config/tim-config'
// initial state
const getDefaultState = () => {
  return {
    scrollTopCount: 0, // 当前聊天对话框，距离顶部的距离
    scrollDirection: 'bottom', // 当前滚动方向
    conversationType: TIM.TYPES.GRP_AVCHATROOM, // 当前会话类型
    conversationID: '',
    to: '', // 发送到谁
    conversation: [], // 消息列表
    previewImgList: [], // 消息中图片列表
    isOpenBullet: true,
    isOnlyComment: false
  }
}
const state = getDefaultState()
const getters = {
  getScrollTopCount: (state) => state.scrollTopCount,

  getTimRoomMsg: state => {
    return state.isOnlyComment ? state.conversation.filter(item => [TIM.TYPES.MSG_TEXT, TIM.TYPES.MSG_IMAGE, TIM.TYPES.MSG_FACE].includes(item.type)) : state.conversation
  },
  getTextMsgList: state => {
    return state.conversation.filter(item => item.type === TIM.TYPES.MSG_TEXT)
  },
  /**
   * 更新消息列表中图片数组
   * 调用时机：点击预览图片时
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  previewImgList: state => {
    return state.conversation
      .filter(item => item.type === TIM.TYPES.MSG_IMAGE && !item.isRevoked)
      .map(item => item.payload.imageInfoArray[0].url)
  }
}
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setConversationID (state, data) {
    state.conversationID = data
  },
  setConversationType (state, data) {
    state.conversationType = data
  },
  setTo (state, data) {
    state.to = data
  },
  setScrollTopCount (state, topVal) {
    state.scrollTopCount = topVal
  },
  setScrollDirection (state, direction) {
    state.scrollDirection = direction
  },
  setIsOpenBullet (state, isOpenBullet) {
    state.isOpenBullet = isOpenBullet
  },
  setIsOnlyComment (state, isOnlyComment) {
    state.isOnlyComment = isOnlyComment
  },
  /**
   * 更新消息列表
   * 调用时机：收/发消息事件触发时 回顶
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  sendMsg (state, data) {
    const { message } = data
    state.conversation.push(message)
    state.conversation = state.conversation.filter(item => item)
    // const sotrArr = _.uniqWith(arr, 'ID')

    emitter.emit('scrollToBottom')
  },
  /**
  * 设置或更新房间群聊消息
  * 调用时机：获取消息列表
  * @param {Object} state
  * @param {Message[]|Message} data
  * @returns
  */
  setConversation (state, data) {
    const { message } = data
    const arr = message.filter(item => item)
    // const sotrArr = _.uniqWith(arr, 'ID')
    state.conversation = arr
    emitter.emit('scrollToBottom')
  },
  /**
   * 删除某条消息
   * 调用时机：点击删除消息
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  deleteMsg (state, data) {
    const { message } = data
    const arr = state.conversation.filter(item => item.ID !== message.ID)
    state.conversation = arr
  },
  /**
   * 重新发送
   * 调用时机：点击重新发送
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  resendMsg (state, data) {
    const { message } = data
    state.conversation = state.conversation.map((item, index) => {
      if (item.ID === message.ID) {
        return message
      }
      return item
    })
  },
  /**
   * 更新消息列表
   * 调用时机：消息撤回时
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  revocation (state, data) {
    const { conversationID, message } = data
    const currentList = state.conversation[conversationID]
    for (let i = 0; i < currentList.length; i++) {
      const item = currentList[i]
      if (item.ID === message.ID) {
        currentList[i] = { ...message }
      }
    }
  },
  refresh (state, data) {
    state.conversation = state.conversation.splice(0)
  },
  updateMessageSpeakerType(state, { userId, speakerType }) {
    state.conversation.forEach(item => {
      if (userId === item.from) {
        item.speakerType = speakerType
      }
    })
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
