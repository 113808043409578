/*
 * @Author: your name
 * @Date: 2020-10-22 14:02:44
 * @LastEditTime: 2021-03-05 18:24:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/utils/request.js
 */
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getToken, clearUserInfo, clearPermission, removeToken } from '@/utils/auth'
// import BASE_CONFIG from 'BASE_CONFIG'
// 创建axios实例
const service = axios.create({
  // baseURL: BASE_CONFIG.BASE_URL, // api 的 base_url
  baseURL: '/', // api 的 base_url
  timeout: 30000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    const token = getToken()
    config['Content-Type'] = 'application/json'
    if (token) {
      config.headers.token = token // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers.uid = token.split('_')[1] // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Cache-Control'] = 'no-cache'
    return config
  },
  (error) => {
    // Do something with request error
    Promise.reject(error)
  }
)
// response 拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    const { code, message } = res
    if (code === 0) {
      return res
    } else if (code === 7402) {
      ElMessage({
        message: '没有所属厂家',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken()
      clearPermission()
      clearUserInfo()
      window.top.location.href = `${window.top.origin}/user/login`
    } else if (code === 401) {
      ElMessage({
        message: '登录失效，请重新进入',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken()
      clearPermission()
      clearUserInfo()
      if (window.location.pathname.indexOf('/user/login') < 0) {
        window.top.location.href = `${window.top.origin}/user/login`
      }
    } else { // todo 异常处理逻辑
      return Promise.reject({
        code: code,
        message: message
      })
    }
  },
  (error) => {
    console.log('err:', error) // for debug
    if (!error.response.status) return
    if (error.response.status === 401 || error.response.status === 403) {
      ElMessage({
        message: '登录失效，请重新进入',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken()
      clearPermission()
      clearUserInfo()
      if (window.location.pathname.indexOf('/user/login') < 0) {
        window.top.location.href = `${window.top.origin}/user/login`
      }
    } else if (error.response.status >= 500) {
      ElMessage({
        message: '请求失败，可能是网络问题引起的，请稍后重试',
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      ElMessage({
        message: '请求失败，可能是网络问题引起的，请稍后重试',
        type: 'error',
        duration: 5 * 1000
      })
    }
    // 返回的err只是字符串 没有返回的必要 反而影响rea.message 所以直接reject 没有返回error
    return Promise.reject()
  }
)
export default service
