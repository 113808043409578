export function filterSpeakerIdentity (arr, speakersList) {
  if (!arr) return
  return arr.map(item => {
    const speaker = speakersList.find(s => `${s.userType}_${s.userId}` === item.from)
    if (speaker) {
      item.identity = 'speaker'
      item.speakerType = speaker.speakerType
    }
    return item
  })
}

const formatSpeaker = (speakersList) => {
  const arr = speakersList && speakersList.map(item => `${item.userType}_${item.userId}`)
  return arr || []
}
/**
 * 数组去重
 * 调用时机：需要取消数组重数据时
 * @param {Object} state
 * @param {Message[]|Message} data
 * @returns
 */
export function removal (arr, key) {
  const result = []// 去重后返回的结果数组
  const temp = {}// 临时对象
  // 将对象数组中每一项的name值作为属性，若temp不拥有此属性时则为temp添加此属性且将其值赋为true，并将这一项push到结果数组中
  for (let i = 0; i < arr.length; i++) {
    const myName = arr[i][key]
    if (temp[myName]) { // 如果temp中已经存在此属性名，则说明遇到重复项
      continue// 不继续执行接下来的代码，跳转至循环开头
    }
    temp[myName] = true// 为temp添加此属性（myName）且将其值赋为true
    result.push(arr[i])// 将这一项复制到结果数组result中去
  }
  return result
}
