<!--
 * @Author: your name
 * @Date: 2021-03-03 21:14:38
 * @LastEditTime: 2021-04-15 17:43:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-group-live/src/layout/Heade r.vue
-->
<template>
  <el-header v-show="userInfo.userType" style="height: 56px">
    <el-row type="flex" align="middle" class="logo">
      <icon name="logos" class="logo" />
    </el-row>
    <el-popover
      trigger="click"
      placement="bottom"
      v-model:visible="showDrop"
      width="160"
      :disabled="!dynamicUserMenu.length"
      v-if="!$route.meta.customHeader"
    >
      <div>
        <div
          class="popover-item color-main pointer"
          @click="onJumpIndex"
          v-if="dynamicUserMenu.includes('index')"
        >
          <i class="el-icon-s-home icon-set"></i> 返回首页
        </div>
        <div
          class="popover-item color-main pointer"
          @click="onChangeFactory"
          v-if="dynamicUserMenu.includes('changeFactory') && userInfo.userType === 2"
        >
          <i class="el-icon-sort icon-set"></i> 切换厂家
        </div>
        <div
          class="popover-item color-main pointer"
          @click="onLogout"
          v-if="dynamicUserMenu.includes('logout')"
        >
          <i class="el-icon-switch-button icon-set"></i>
          退出登录
        </div>
      </div>
      <template #reference>
        <el-row type="flex" align="middle" class="pointer">
          <el-image class="user-photo" :src="avatar">
            <!-- <div slot="error" class="image-slot">
              <img src="../assets/user/represent_user_label.png" alt="">
            </div> -->
            <template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </template>
          </el-image>
          <div>
            <div class="user-name font-size-large text-ellipsis" :title="userInfo.name">{{userInfo.name}}</div>
            <div class="user-desc text-ellipsis" :title="desc">{{desc}}</div>
          </div>
          <i
            class="el-icon-caret-bottom icon-base"
            v-bind:class="{active: showDrop}"
            v-if="dynamicUserMenu.length"
          />
        </el-row>
      </template>
    </el-popover>
    <div id="customHeader" v-show="$route.meta.customHeader"></div>
    <SelectFactoryAccountModal :visible="isShow" @close:dialog="onClose" />
  </el-header>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SelectFactoryAccountModal from '@/bizComponents/SelectFactoryAccountModal'

export default {
  name: 'Header',
  data () {
    return {
      showDrop: false,
      showChange: false,
      showFactoryModal: false
    }
  },
  components: {
    SelectFactoryAccountModal
  },
  computed: {
    ...mapGetters(['userInfo', 'dynamicUserMenu']),
    isShow () {
      return (this.userInfo.userType === 2 && !this.userInfo.orgId) || this.showFactoryModal
    },
    desc () {
      // 2代表 1 医生
      return this.userInfo.userType === 2 ? this.userInfo.orgName : this.userInfo.hospital
    },
    avatar () {
      if (this.userInfo.avatarUrl) {
        return this.userInfo.avatarUrl
      } else if (this.userInfo.userType === 2) {
        return require('@/assets/user/represent_user_label.png')
      } else {
        return require('@/assets/user/doctor_user_label.png')
      }
    }
  },
  methods: {
    ...mapActions(['addUserMenu', 'removeUserMenu']),
    onJumpIndex () {
      this.$router.push('/index')
    },
    onLogout () {
      this.showDrop = false
      this.$router.push('/user/login')
    },
    onClose () {
      console.log(1)
      this.showFactoryModal = false
    },
    onChangeFactory () {
      this.showFactoryModal = true
    }
  },
  watch: {
    '$route.name': function (val) {
      // 首页允许切换厂家
      if (val === 'Index') {
        this.addUserMenu(['changeFactory'])
      } else {
        this.removeUserMenu(['changeFactory'])
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
  .el-header {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #fff;
    padding: 0 35px;
    height: 66px;
    color: #0f0f0f;

    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 5px 0 #EEEEEE;
  }

  .logo {
    height: 100%;

    .logo {
      width: 100%;
      height: 100%;
      min-width: 300px;
      cursor: pointer;
    }

    .title {
      font-size: 26px;
      color: #3D61E3;
    }
  }

  .user-photo {
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }

  .icon-base {
    font-size: 20px;
    margin-left: 5px;
    transition: all 150ms;
    color: #A3A3A3;
  }

  .user-desc, .user-name {
    width: 120px;
  }

  .user-desc {
    color: #787878;
  }

  .popover-item {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #198cff;

      .icon-set {
        color: #198cff;
      }
    }

    .icon-set {
      font-size: 20px;
      color: #e1e2e6;
      margin-right: 18px;
    }
  }

  .image-slot {
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
