import * as Types from '../../mutations-types'

export default {
  [Types.GET_GROUP_LIVE_LIST] (state, data) {
    state.groupLiveList = data
  },
  setLiveStatus (state, status) {
    const n = parseFloat(status)
    state.liveStatus = isNaN(n) ? '' : status
  },
  setMode (state, mode) {
    state.mode = mode
  },
  updateDeviceInfo (state, deviceInfo) {
    state.deviceInfo = { ...state.deviceInfo, ...deviceInfo }
  },
  updateControllers (state, controllers) {
    state.controllers = { ...state.controllers, ...controllers }
  },
  updateLayout (state, layout) {
    const templateImg = state.layout.templateImg // 更新需要保留值
    state.layout = { templateImg, ...layout }
  },
  updateLayoutTemplateImg (state, payload) {
    state.layout.templateImg = payload
  },
  updatePublishStatus (state, status) {
    state.streamPublishStatus = status
  },
  updateLiveInfo (state, data) {
    state.liveInfo = data
  },
  updateLiveInfoValue (state, data) {
    state.liveInfo = { ...state.liveInfo, ...data }
  },
  updateWebinarMembers (state, data) {
    if (state.liveInfo.speakers) {
      state.liveInfo.speakers = data
    }
  },
  updateConnectApplyWaiting (state, status) {
    state.connectApplyWaiting = status
  },
  updateConnectionMembers (state, memberArray) {
    state.connectionMembers = memberArray
  }
}
