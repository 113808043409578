export default {
  updateMicrophoneDeviceId(state, deviceId) {
    state.microphone.id = deviceId
  },
  updateMicrophoneMuteStatus(state, status) {
    state.microphone.mute = status
  },
  updateCameraDeviceId(state, deviceId) {
    state.camera.id = deviceId
  },
  updateCameraMuteStatus(state, status) {
    state.camera.mute = status
  },
  updateDeviceInfo(state, { type, info }) {
    state[type] = Object.assign(state[type], info)
  },
  updateCoursewareUrl(state, coursewareUrl) {
    state.coursewareUrl = coursewareUrl
  },
  updateGuestList(state, guestList) {
    state.guestList = guestList
  },
  addGuest(state, guest) {
    state.guestList.push(guest)
  },
  removeGuest(state, userId) {
    const list = []
    let offlineItem = {}
    state.guestList.forEach(item => {
      if (item.userId === userId) {
        item.stream = null
        offlineItem = item
      } else {
        list.push(item)
      }
    })
    list.push(offlineItem)
    state.guestList = list
  },
  updateGuest(state, { userId, obj }) {
    state.guestList.forEach(item => {
      if (item.userId === userId) {
        Object.assign(item, obj)
      }
    })
    // 有用户上线，更新列表
    if (obj.stream) {
      state.guestList = orderGuestList(state.guestList)
    }
  },
  updateSharInfo(state, shareInfo) {
    state.shareInfo = Object.assign(state.shareInfo, shareInfo)
  },
  resetShareInfo(state) {
    state.shareInfo = {
      stream: null,
      userId: '',
      identity: 1,
      isMe: true,
      coursewareUrl: ''
    }
  },
  updateRoomConfig(state, roomConfig) {
    state.roomConfig = Object.assign(state.roomConfig, roomConfig)
  },
  updateUserConfig(state, userConfig) {
    state.userConfig = Object.assign(state.userConfig, userConfig)
  },
  updateIsFullScreen(state, isFullScreen) {
    state.isFullScreen = isFullScreen
  }
}

function orderGuestList(sourceList = []) {
  const targetList = []
  const online = []
  const offline = []
  sourceList.forEach(item => {
    if (item.isMe) {
      targetList.push(item)
    } else if (item.stream) {
      online.push(item)
    } else {
      offline.push(item)
    }
  })
  return targetList.concat(online, offline)
}
