import { ElMessage } from 'element-plus'

const SDKAppIdData = {
  localhost: 1400388764,
  'live.yaomaitong.net': 1400388764,
  'live.yaomaitong-pre.cn': 1400404508,
  'live.quxueshu.com': 1400404508
}
const state = {
  SDKAPPID: SDKAppIdData[window.location.hostname] || SDKAppIdData.localhost,
  level: 0, // 日志级别
  ready: false
}
const getters = {
  getLevel: state => {
    return state.level
  }
}
const actions = {}

const mutations = {
  /**
   * 设置 sdk 日志级别
   * 调用时机：启动项目时根据环境变量设置
   * @param {Object} state
   * @returns
   */
  setLevel (state) {
    const env = process.env.NODE_ENV
    state.level = env === 'development' ? 0 : env === 'test' ? 1 : env === 'production' ? 4 : 2
  },
  /**
   * 更新 sdk 初始化状态
   * 调用时机：sdk 初始化成功或失败时
   * @param {Object} state
   * @param {data | Boolean} data
   * @returns
   */
  updateReadyStatus (state, data) {
    state.ready = data
  },

  showMessage (state, options) {
    if (state.message) {
      state.message.close()
    }
    state.message = ElMessage({
      message: options.message,
      type: options.type || 'success',
      duration: options.duration || 2000,
      offset: 40
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
