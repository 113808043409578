/*
 * @Author: your name
 * @Date: 2020-10-28 17:46:02
 * @LastEditTime: 2021-03-06 15:54:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/common/index.js
 */
import actions from './action'
import mutations from './mutation'

const userAgentData = window.navigator.userAgentData
const state = {
  isChrome: userAgentData?.brands?.some(b => b.brand === 'Google Chrome')
}
const getters = {
  isChrome: state => state.isChrome
}
export default {
  state,
  getters,
  actions,
  mutations
}
