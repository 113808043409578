import * as Types from '../../mutations-types'

export default {
  initUserMenu ({ commit }) {
    commit(Types.RESET_DYNAMIC_USER_MENU)
  },
  removeUserMenu ({ state, commit }, removeMenus = []) {
    const newMenu = []
    state.dynamicUserMenu.forEach(menu => {
      if (!removeMenus.includes(menu)) newMenu.push(menu)
    })
    commit(Types.SET_DYNAMIC_USER_MENU, newMenu)
  },
  addUserMenu ({ state, commit }, addMenus = []) {
    const newMenu = [...new Set([...state.dynamicUserMenu, ...addMenus])]
    commit(Types.SET_DYNAMIC_USER_MENU, newMenu)
  },
  hideUserMenu ({ commit }) {
    commit(Types.HIDE_USER_MENU)
  },
  keepUserMenu ({ state, commit }, keepMenus = []) {
    const newMenu = []
    state.dynamicUserMenu.forEach(menu => {
      if (keepMenus.includes(menu)) newMenu.push(menu)
    })
    commit(Types.SET_DYNAMIC_USER_MENU, newMenu)
  }
}
