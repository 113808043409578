<template>
  <svg :class="`${props.className ? 'svg-icon ' + props.className : 'svg-icon'}`" aria-hidden="true">
    <use :xlink:href="`#svg-${props.name}`"></use>
  </svg>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    name: { type: String },
    className: { type: String }
  },
  setup(props) {
    return { props }
  }
}
</script>
<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
