const getDefaultState = () => {
  return {
    userID: '', // 用户id
    groupID: '', // 群组ID
    bannedUserList: [],
    qresenterIDList: []
  }
}
const state = getDefaultState()
const getters = {}
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setUserID (state, data) {
    state.userID = data
  },
  setGroupID (state, data) {
    state.groupID = data
  },
  setBannedUserList (state, data) {
    state.bannedUserList = data
  },
  setOresenterIDList (state, data) {
    state.qresenterIDList = data
  },
  updateSpeakerType(state, { userId, speakerType }) {
    state.qresenterIDList.forEach(item => {
      if (userId === `${item.userType}_${item.userId}`) {
        item.speakerType = speakerType
      }
    })
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
