import { defineComponent } from 'vue'

function withInstall (comp) {
  const c = comp
  c.install = function (app) {
    app.component(c.displayName || c.name, comp)
  }
  return comp
}

const icon = defineComponent({
  name: 'Icon',
  props: {
    name: String
  },
  render () {
    const { name } = this
    return (
      <svg class="icon-font" aria-hidden="true">
        <use xlinkHref={`#icon-${name}`}></use>
      </svg>
    )
  }
})
export default withInstall(icon)
