/*
 * @Author: your name
 * @Date: 2020-11-25 21:09:16
 * @LastEditTime: 2021-03-06 15:16:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/doctor/action.js
 */
import * as UserService from '@/services/userService'
import * as Types from '../../mutations-types'

export default {
  getWxCodeImage ({ commit, state }, params) {
    return UserService.getWxCodeImage(params)
      .then(res => {
        commit(Types.GET_WX_CODE_IMAGE, res.data)
        return Promise.resolve(res.data)
      }, rea => {
        return Promise.reject(rea)
      })
  },
  checkWxCodeResult ({ commit, state }, params) {
    return UserService.checkWxCodeResult(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  userLogin ({ commit, state }, params) {
    return UserService.userLogin(params).then(res => {
      // commit(Types.SET_USER_INFO,res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getUserInfo ({ commit }) {
    return UserService.getUserInfo().then(res => {
      commit(Types.SET_USER_INFO, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  userMobileLogin ({ commit, state }, params) {
    return UserService.userMobileLogin(params).then(res => {
      const { data } = res
      if (data.orgLoginVo) {
        commit(Types.SET_USER_INFO, data.userInfo)
      }
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getSmsCode ({ commit, state }, params) {
    return UserService.getSmsCode(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getFactoryAccountList ({ commit, state }) {
    return UserService.getFactoryAccountList().then(res => {
      commit(Types.SET_FACTORY_ACCOUNT_LIST, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  onCheckFactoryAccount ({ commit, state }, item) {
    return UserService.onCheckFactoryAccount(item.id)
      .then(res => {
        commit(Types.ON_CHECK_FACTORY_ACCOUNT, item)
        return Promise.resolve(res)
      }, rea => {
        return Promise.reject(rea)
      })
  },
  ///  weiyongdao
  setNormalAccount ({ commit, state }, params) {
    return UserService.setNormalAccount(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getPaymentCodeImage ({ commit, state }, params) {
    return UserService.getWxCodeImage(params).then(res => {
      commit(Types.CHECK_PAYMENT_CODE_IMAGE, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  /// mir
  checkPaymentCodeResult ({ commit, state }, params) {
    return UserService.checkWxCodeResult(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getAccountInfo ({ commit, state }, params) {
    return UserService.getAccountInfo(params).then(res => {
      commit(Types.SET_ACCOUNT_INFO, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getAccountScore ({ commit, state }, params) {
    return UserService.getAccountScore(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getLoginRecord ({ commit, state }, params) {
    return UserService.getLoginRecord(params).then(res => {
      commit(Types.SET_LOGIN_RECORD, res.data.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getSensitiveRecord ({ commit, state }, params) {
    return UserService.getSensitiveRecord(params).then(res => {
      commit(Types.SET_SENSITIVE_RECORD, res.data.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  bindMobile ({ commit, state }, params) {
    return UserService.bindMobile(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  changeMobile ({ commit, state }, params) {
    return UserService.changeMobile(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  changePwd ({ commit, state }, params) {
    return UserService.changePwd(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  setNewPassword ({ commit, state }, params) {
    return UserService.setNewPassword(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  paymentCheckValue ({ commit, state }, params) {
    return UserService.paymentCheckValue(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  paymentLimit ({ commit, state }, params) {
    return UserService.paymentLimit(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  checkCaptcha ({ commit, state }, params) {
    return UserService.checkCaptcha(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  bindWechat ({ commit, state }, params) {
    return UserService.bindWechat(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  }
}
