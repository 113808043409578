/*
 * @Author: your name
 * @Date: 2020-12-10 17:48:15
 * @LastEditTime: 2021-03-04 14:03:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/utils/auth.js
 */
import Cookies from 'js-cookie'
// import { getQueryVariable } from './util'

// const TokenKey = 'Admin-Token'

export function getToken () {
  // 先获取personInfo的token
  return window.localStorage.getItem('_token') || ''
}

export function setToken (token) {
  window.localStorage.setItem('_token', token)
}

export function removeToken () {
  window.localStorage.removeItem('_token')
}

export const setUserInfo = (cvalue = {}, cname = 'userInfo', exdays = 30) => {
  cvalue = JSON.stringify(cvalue)
  setCookie(cvalue, cname, exdays)
}

export const getUserInfo = () => {
  // 只通过personInfo获取info
  const userInfo = getCookie('userInfo')
  return userInfo ? JSON.parse(userInfo) : {}
}

export const clearUserInfo = () => deleteCookie('userInfo')
export const setCookie = (cvalue = '', cname = '', exdays = 30) => {
  Cookies.set(cname, cvalue, { expires: exdays, path: '/', domain: document.domain })
}

export const getCookie = (cname) => {
  return Cookies.get(cname)
}

export const deleteCookie = (cname) => {
  Cookies.remove(cname, { path: '/', domain: document.domain })
}

// 记录权限
export const setPermission = (cvalue = '', cname = 'permission') => {
  cvalue = JSON.stringify(cvalue)
  window.sessionStorage.setItem(cname, cvalue)
}

export const getPermission = (cname = 'permission') => {
  const permission = window.sessionStorage.getItem(cname)
  return permission ? JSON.parse(permission) : null
}

export const clearPermission = () => window.sessionStorage.removeItem('permission')
