// 数据导出模块
import request from '@/utils/request'
import axios from 'axios'
import API, { rtc } from '@/apis/api-types'

export function getGroupLiveList ({ pageNo = 1, pageSize = 10, sceneType = null }) {
  return request({
    method: 'get',
    url: API.getGroupLiveList,
    params: { pageNo, pageSize, sceneType }
  })
}

export function getWatchUrl (liveId = '') {
  return request({
    method: 'get',
    url: API.getWatchUrl,
    params: { liveId }
  })
}

export function getLiveInfoByTicket (ticket = '') {
  return request({
    method: 'get',
    url: API.getLiveInfoByTicket,
    params: { ticket }
  })
}

export function changeLiveStatusByTicket ({ ticket = '', action = '' }) {
  return request({
    method: 'get',
    url: API.changeLiveStatusByTicket,
    params: { ticket, action }
  })
}

export function getRtcAnswerSdp (data) {
  return axios({
    method: 'post',
    mode: 'cors',
    url: rtc.getRtcAnswerSdp,
    data
  })
}

export function stopRtcStream (data) {
  return axios({
    method: 'post',
    mode: 'cors',
    url: rtc.stopRtcStream,
    data
  })
}

export function getCoursewareList (params) {
  return request({
    method: 'get',
    url: API.getCoursewareList,
    params
  })
}

export function uploadByTicket ({ ticket, file }) {
  const params = new FormData()
  params.append('ticket', ticket)
  params.append('file', file)
  return request({
    url: API.uploadFileByTicket,
    method: 'post',
    data: params
  })
}

export function transcodeCourseware (params) {
  return request({
    url: API.transcodeCourseware,
    method: 'get',
    params
  })
}

export function deleteCourseware (params) {
  return request({
    url: API.deleteCourseware,
    method: 'get',
    params
  })
}

export function checkCurrentPublish (params) {
  return request({
    url: API.getCurrentPublishUserId,
    method: 'get',
    params
  })
}

export function uploadTemplateImg (params) {
  return request({
    url: API.uploadTemplateImg,
    method: 'get',
    params
  })
}

export function liveModeChange (params) {
  return request({
    url: API.liveModeChange,
    method: 'get',
    params
  })
}

export function setConnectionStatus (params) {
  return request({
    url: API.setConnectionStatus,
    method: 'get',
    params
  })
}

export function guestApplyOnLine (params) {
  return request({
    url: API.applyOnline,
    method: 'get',
    params
  })
}

export function setGuestOffLine (params) {
  return request({
    url: API.offlineGuest,
    method: 'get',
    params
  })
}

export function setMainSpeaker (params) {
  return request({
    url: API.setMainSpeaker,
    method: 'get',
    params
  })
}

export function getConnectionMembers (params) {
  return request({
    url: API.getConnectionMembers,
    method: 'get',
    params
  })
}

export function updateNetworkQuality (params) {
  return request({
    url: API.updateNetworkQuality,
    method: 'get',
    params
  })
}

export function getUserSig({ ticket, uidList }) {
  return request({
    url: `${API.getUserSig}?ticket=${ticket}&uidList=${uidList.join(',')}`,
    method: 'get'
  })
}

export function setWebinarForbidden({ ticket, forbidden }) {
  return request({
    url: `${API.setWebinarForbidden}?ticket=${ticket}&forbidden=${forbidden}`,
    method: 'get'
  })
}

export function setCoPresent(query) {
  const queryList = Object.keys(query).map(key => {
    return `${key}=${query[key]}`
  })
  return request({
    url: `${API.setCoPresent}?${queryList.join('&')}`,
    method: 'get'
  })
}

export function setCoursewarePermission(query) {
  const queryList = Object.keys(query).map(key => {
    return `${key}=${query[key]}`
  })
  return request({
    url: `${API.setCoursewarePermission}?${queryList.join('&')}`,
    method: 'get'
  })
}

export function setFeedback(ticket, body) {
  return request({
    url: `${API.setFeedback}?ticket=${ticket}`,
    data: body,
    method: 'post'
  })
}

export function setShareControl({ ticket, shareControl }) {
  return request({
    url: `${API.setShareControl}?ticket=${ticket}&permissionState=${shareControl}`,
    method: 'get'
  })
}

export function setWebinarLayout({ ticket, layout }) {
  return request({
    url: `${API.setWebinarLayout}?ticket=${ticket}&layout=${layout}`,
    method: 'get'
  })
}

export function setStreamPushSuccess(ticket) {
  return request({
    url: `${API.setStreamPushSuccess}?ticket=${ticket}`,
    method: 'get'
  })
}

export function getLiveStatus(ticket) {
  return request({
    url: `${API.getLiveStatus}?ticket=${ticket}`,
    method: 'get'
  })
}
export function getWebinarInfo(ticket) {
  return request({
    url: `${API.getWebinarInfo}?ticket=${ticket}`,
    method: 'get'
  })
}

export function reportStartPushStream(ticket) {
  return request({
    url: `${API.reportStartPushStream}?ticket=${ticket}`,
    method: 'get'
  })
}

export function reportPushStreamStatus(ticket, result) {
  return request({
    url: `${API.reportPushStreamStatus}?ticket=${ticket}&result=${result}`,
    method: 'get'
  })
}

export function getLiveDuration(ticket) {
  return request({
    url: `${API.getLiveDuration}?ticket=${ticket}`,
    method: 'get'
  })
}

export function reportStreamQuality(body) {
  return request({
    url: `${API.reportStreamQuality}`,
    data: body,
    method: 'post'
  })
}
