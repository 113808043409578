/*
 * @Author: your name
 * @Date: 2020-10-22 14:02:44
 * @LastEditTime: 2021-03-05 15:55:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/mutations-types.js
 */
// 页面状态
export const REQUEST_DOING = 'REQUEST_DOING'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_FAILED = 'REQUEST_FAILED'
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
// 首页
export const GET_GROUP_LIVE_LIST = 'GET_GROUP_LIVE_LIST'
// 用户
export const GET_WX_CODE_IMAGE = 'GET_WX_CODE_IMAGE'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION'
export const SET_FACTORY_ACCOUNT_LIST = 'SET_FACTORY_ACCOUNT_LIST'
export const ON_CHECK_FACTORY_ACCOUNT = 'ON_CHECK_FACTORY_ACCOUNT'
export const CHECK_PAYMENT_CODE_IMAGE = 'CHECK_PAYMENT_CODE_IMAGE'
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO'
export const SET_LOGIN_RECORD = 'SET_LOGIN_RECORD'
export const SET_SENSITIVE_RECORD = 'SET_SENSITIVE_RECORD'
// 用户菜单
export const HIDE_USER_MENU = 'HIDE_USER_MENU'
export const SET_DYNAMIC_USER_MENU = 'SET_DYNAMIC_USER_MENU'
export const RESET_DYNAMIC_USER_MENU = 'RESET_DYNAMIC_USER_MENU'
