/*
 * @Author: your name
 * @Date: 2020-10-28 17:45:55
 * @LastEditTime: 2021-03-05 16:08:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/common/action.js
 */
import * as Types from '../../mutations-types'
import {
  getGroupLiveList,
  getWatchUrl,
  getLiveInfoByTicket,
  changeLiveStatusByTicket,
  getConnectionMembers
} from '@/services/liveService'

export default {
  getGroupLiveList ({ commit, state }, params) {
    return getGroupLiveList(params)
      .then(res => {
        commit(Types.GET_GROUP_LIVE_LIST, res.data.data)
        return Promise.resolve(res.data)
      }, rea => {
        return Promise.reject(rea)
      })
  },
  getWatchUrl ({ commit, state }, params) {
    return getWatchUrl(params)
      .then(res => {
        return Promise.resolve(res.data)
      }, rea => {
        return Promise.reject(rea)
      })
  },
  getLiveInfoByTicket ({ commit, state }, ticket) {
    return getLiveInfoByTicket(ticket)
      .then(res => {
        const { userInfo, liveStatus, speakers } = res.data
        commit(Types.SET_USER_INFO, userInfo, { root: true })
        commit('setLiveStatus', liveStatus)
        commit('updateConnectionMembers', speakers.filter(i => i.connectStatus === 1))
        return Promise.resolve(res)
      }, rea => {
        return Promise.reject(rea)
      })
  },
  changeLiveStatusByTicket ({ commit, state }, params) {
    return changeLiveStatusByTicket(params)
      .then(res => {
        if (res.data) {
          commit('setLiveStatus', res.data)
        }
        return Promise.resolve(res)
      }, rea => {
        return Promise.reject(rea)
      })
  },
  // 重置直播状态
  initLiveRoom ({ commit }) {
    commit('setLiveStatus', '')
  },
  fetchConnectionMembers ({ commit, state }, params) {
    return getConnectionMembers(params)
      .then(res => {
        commit('updateWebinarMembers', res.data)
        commit('updateConnectionMembers', res.data.filter(i => i.connectStatus === 1))
        return Promise.resolve(res)
      }, rea => {
        return Promise.reject(rea)
      })
  }
}
