<!--
 * @Author: your name
 * @Date: 2020-10-22 14:02:44
 * @LastEditTime: 2021-03-11 19:15:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/App.vue
-->
<template>
  <div id="inner_app" :style="{position:positionSet}">
    <Layout>
      <router-view v-slot="{ Component }">
        <keep-alive include="Index">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </Layout>

  </div>
</template>
<script>
// @ is an alias to /src
import Layout from '@/layout/Layout'

export default {
  components: {
    Layout
  },
  computed: {
    positionSet () {
      return this.$route.name === 'UserLogin' ? 'initial' : 'relative'
    }
  },
  methods: {},
  mounted () {
  }
}
</script>

<style lang="scss">
  #inner_app, #app {
    height: 100%;
    /*max-width: 1800px;*/
    margin: 0 auto;
  }
</style>
