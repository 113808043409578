import { createApp } from 'vue'
import router from '@/router'
import store from '@/store'
import { parseUid, setUid } from '@/utils/ali-log'
import { getToken } from '@/utils/auth'
import '@/styles/element/index.scss'
import '@/styles/index.scss'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import Icon from '@/components/icon'
import SvgIcon from '@/components/SvgIcon/index.vue'
import App from './App.vue'
import '@/router/permission'

// 已登录刷新页面之后设置一下uid
setUid(parseUid(getToken()))
// 自动加载 svg 图标
const req = require.context('@/assets/icons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
const app = createApp(App)
app.component(SvgIcon.name, SvgIcon)
app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn
  })
  .use(Icon)
  .mount('#app')
