/*
 * @Author: your name
 * @Date: 2020-12-10 17:48:15
 * @LastEditTime: 2021-03-05 16:31:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/store/module/user/mutation.js
 */
import * as Types from '../../mutations-types'
import { setPermission, setUserInfo, getUserInfo } from '@/utils/auth'

export default {
  [Types.GET_WX_CODE_IMAGE] (state, res) {
    state.wxCodeImage = res
  },
  [Types.SET_USER_INFO] (state, res) {
    setUserInfo(res)
    state.userInfo = res
  },
  [Types.SET_USER_PERMISSION] (state, res) {
    const permissionObj = {}
    res.forEach(item => {
      permissionObj[item] = true
    })
    setPermission(permissionObj)
    state.permission = res
  },
  [Types.SET_FACTORY_ACCOUNT_LIST] (state, res) {
    state.factoryAccountList = res
    state.isHandleFactory = false
  },
  [Types.ON_CHECK_FACTORY_ACCOUNT] (state, item) {
    const userInfo = getUserInfo()
    userInfo.orgName = item.name
    userInfo.orgId = item.id
    state.userInfo = userInfo
    state.isHandleFactory = true
    setUserInfo(userInfo)
  },
  [Types.CHECK_PAYMENT_CODE_IMAGE] (state, res) {
    state.paymentCodeImage = res
  },
  [Types.SET_ACCOUNT_INFO] (state, res) {
    state.accountCenterInfo = res
  },
  [Types.SET_LOGIN_RECORD] (state, res) {
    state.accountLoginRecord = res
  },
  [Types.SET_SENSITIVE_RECORD] (state, res) {
    state.accountSensitiveRecord = res
  }
}
